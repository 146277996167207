<template>
  <div class="appointment">
    <div>
      <h3>{{ $t('onboarding.appointment.title') }}</h3>
      <div v-html="$simpleFormat($t('onboarding.appointment.text', { name: firstName }))"></div>
    </div>

    <b-button class="m-t-xl full-width" variant="primary"
              href="https://outlook.office365.com/owa/calendar/KennenlernCallPrologistics@flipcar.app/bookings/">
      {{ $t('onboarding.appointment.button') }}
    </b-button>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    firstName() {
      return this.current_user?.first_name || ''
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>


